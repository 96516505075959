import React from "react"
import { useTranslation } from "react-i18next"

const SchoolItemStudyCenters = props => {
  const { t } = useTranslation()

  const studyCenters = props.studyCenters || []
  return (
    <div className="white-block about-school-block study-centers-wrapper">
      <div className="about-school-content-wrapper container">
        <div className="row mt-5">
          {studyCenters
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(study => (
              <div
                key={study.id}
                className="school-item-comparison-wrapper-cover d-flex gap-3 col-lg-10 align-items-center pb-3 mb-5"
              >
                <img
                  src={study.resolvedImageUrl}
                  alt="resolvedImageUrl"
                  className="school-comparison-image"
                />
                <div className="school-comparison-main-info">
                  <div className="saved-school-title">{study.name}</div>
                  <div className="saved-school-text">
                    {t("school.scholarships.age")}: {study.ageMin}–
                    {study.ageMax}
                  </div>
                  {study.childrenPerBedRoomMin === study.childrenPerBedRoomMax &&
                    <div className="saved-school-text">
                      {t("school.studyCenter.childrenPerBedroom")}: {study.childrenPerBedRoomMin}
                    </div>
                  }
                  {((study.childrenPerBedRoomMin && study.childrenPerBedRoomMax)
                    && (study.childrenPerBedRoomMin !== study.childrenPerBedRoomMax)) &&
                    <div className="saved-school-text">
                      {t("school.studyCenter.childrenPerBedroom")}: {study.childrenPerBedRoomMin}–
                      {study.childrenPerBedRoomMax}
                    </div>
                  }
                  {study?.courses && (
                    <div className="saved-school-text">
                      {t("school.scholarships.programs")}: {study.courses}{" "}
                    </div>
                  )}
                  <div
                    className="saved-school-text school-levels"
                    dangerouslySetInnerHTML={{ __html: study.description }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SchoolItemStudyCenters
