import React from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"
import Layout from "../../components/Layout"
import SchoolItemStudyCenters from "../../sections/schools/SchoolItemStudyCenters"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"

const SchoolPageSummerCenters = props => {
  return (
    <Layout
      wrapperClass={"school-item subjects-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state5="active"
        school={props.pageContext.currentSchool}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemStudyCenters
        studyCenters={props.pageContext.currentSchoolScholarships}
      />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={props.pageContext.summerSchools}
      />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageSummerCenters
